.about {
  background: $white-color;
  padding-bottom: 60px;
}

.about-info{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-info-top{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-skills{
  display: flex;
  flex-direction: column;
  align-items: center;
}