.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: $white-color;
    padding-bottom: 40px;
}

.contact-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-icon {
    margin-bottom: 1rem;
    // transition: all .5s ease;
}

.contact-icon:hover {
    color: $accent-color;
    filter: drop-shadow(2px 2px 2px #000000);
}

.contact-row {
  display: flex;
  flex-direction: row;
  align-content: space-between;
  padding-bottom: 2rem;
}

.contact-text {
  color: $dark-color2;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
}

.footer-text {
  display:flex;
  flex-direction: column;
  color: $dark-color2;
  font-family: 'Roboto', sans-serif;
  font-size: .7rem;
  // margin-top: 1rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.thank-you-text {
  display:flex;
  flex-direction: column;
  color: $dark-color;
  font-family: 'Alfa Slab One', 'cursive';
  font-size: 1.5rem;
  margin-top: 2.8rem;
  align-items: center;
  justify-content: center;
  text-align: center;
}
