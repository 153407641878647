@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Noto+Serif&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

h1 {
  // font-weight: 1;
  // font-family: 'Alfa Slab One';
}

.about p {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: $default-font-size;
  color: $dark-color;
  text-align: center;
}