@import url('https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css');


.hero {
  min-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0px;
  background: $dark-color;
  font-weight: 400;
  // padding: 0rem 5.6rem;
  margin-bottom: 0;
  color: none;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}

.hero-title {
  font-family: 'Alfa Slab One', 'cursive';
  font-size: $big-font-size;
  font-weight: 100;
  // margin-bottom: 3.2rem;
  text-align: left;
  color: $title-text;
  // display: flex;
  // align-items: flex-start;
}

.hero-accent {
  font-family: 'Alfa Slab One', 'cursive';
  font-size: 4.5rem;
  // font-weight: 400;
  margin-bottom: 3.2rem;
  text-align: left;
  color: $accent-color;
}

.hero-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  animation: bounce; 
  animation-duration: 6s; 
}

.arrow {
  border: $accent-color;
  border-width: 0 10px 10px 0;
  display: inline-block;
  padding: 20px;
  border-style: solid;
  height: 20px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}