.project {
  // padding:40px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-info {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40vw;
  text-align: center;
  padding:20px 0px;
  width: 40vw;
  text-justify: inter-word;
}

.projects-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40vw;
  margin-bottom: .5rem;
  border-style: solid;
  border-color: $white-color;
  border-width: 2px;
}

.projects-info-text {
  color: $dark-color2;
  font-size: 1rem;
  text-align: justify;
}

.projects-info-text2 {
  color: $dark-color2;
  font-size: 1rem;
  text-align: justify;
  font-weight: 700;
}

.projects-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: .5rem;
}

.project-button {
  margin: .5rem;
  width: 6rem;
  height: 2rem;
  // border-color: $accent-color;
  // border-style: solid;
  // border-width: 4px;
  color: $dark-color;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  background: $accent-color;
  border-style:none;
}

.project-button :hover {
  background: $accent-color;
  color: $white-color;
}