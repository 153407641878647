$white-color: rgb(228, 238, 241);
$dark-color: rgb(99, 126, 141);
$dark-color2: rgb(63, 87, 100);
// $accent-text: #a6e3ff; 
$accent-color: #a6e3ff; 
$title-text:  rgb(243, 250, 255); 

// FONT SIZE
$default-font-size: 1.5rem;
$big-font-size: 4.5rem;
$mid-font-size: 2.5rem;
$small-font-size: 1.5rem;
